import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { IntlProvider } from 'react-intl'
import {ToastContextProvider} from "./ToastContext";
import getData from './components/data/getData';
import CheckForms from "./views/checkForm/CheckForms";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const CheckForm = React.lazy(() => import('./views/checkForm/CheckForm'))
const CheckModel = React.lazy(() => import('./views/checkModel/CheckModel'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))


function App() {
  const [locale, setLocale] = useState(navigator.language.split('-')[0])

  useEffect(() => {
    const handleLanguageChanged = (newLocale) => {
      setLocale(newLocale)
    }

    i18n.on('languageChanged', handleLanguageChanged)

    return () => {
      i18n.off('languageChanged', handleLanguageChanged)
    }
  }, [])

  return (
    <IntlProvider locale={locale}>
      <I18nextProvider i18n={i18n}>
        <ToastContextProvider>
          <HashRouter>
            <Suspense fallback={loading}>
              <Routes>
                <Route exact path="/login" name="Login Page" element={<Login />} />
                <Route exact path="/checkForm/:id" name="CheckForm" element={<CheckForm />} />

                <Route exact path="/checkModel/:id" name="AuditModel" element={<CheckModel />} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />
                <Route path="*" name="Home" element={<DefaultLayout />} />
              </Routes>
            </Suspense>
          </HashRouter>
        </ToastContextProvider>
      </I18nextProvider>
    </IntlProvider>
  )
}

export default App
