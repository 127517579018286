import React from 'react';
import {
  CBadge,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CRow,
} from "@coreui/react-pro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faLocationPin,
  faUsers,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import { formatDateInLocale } from "../../Utils";
import { locale } from "moment";
import { useTranslation } from "react-i18next";


export default function CheckFormsList({ checkForms, handleCardClick, statusColors }) {
  const { t } = useTranslation();

  return (
    <CRow>
      {checkForms.map((item, index) => (
        <CCol key={index} xs={12}>
          <CCard className="mb-3" onClick={() => handleCardClick(item.id)} style={{ cursor: 'pointer' }}>
            <CCardHeader>
              {item.name}
            </CCardHeader>
            <CCardBody>
              {item.buildingName && (
                <p className="font-weight-bold">
                  <FontAwesomeIcon className="me-2" icon={faBuilding} />
                  <span className="me-2">{item.buildingName}</span>
                </p>
              )}

              {item.checkServices && item.checkServices.length > 0 && (
                <p className="font-weight-bold">
                  <FontAwesomeIcon className="me-2" icon={faLocationPin} />
                  {item.checkServices.join(", ")}
                </p>
              )}

              {item.checkUsers && item.checkUsers.length > 0 && (
                <p className="font-weight-bold">
                  <FontAwesomeIcon className="me-2" icon={faUsers} />
                  {item.checkUsers.join(", ")}
                </p>
              )}

              <p className="font-weight-bold">
                <FontAwesomeIcon className="me-2" icon={faCalendarDays} />
                {item.plannedStartDate === null
                  ? t("not_informed")
                  : `${t("planned_start_date")}: ${formatDateInLocale(item.plannedStartDate, locale, true)}`}
              </p>

              <p className="font-weight-bold">
                <FontAwesomeIcon className="me-2" icon={faCalendarDays} />
                {item.plannedEndDate === null
                  ? t("not_informed")
                  : `${t("planned_end_date")}: ${formatDateInLocale(item.plannedEndDate, locale, true)}`}
              </p>
            </CCardBody>
            <CCardFooter>
              <CBadge color={statusColors[item.checkStatus]} className="ms-2" shape="rounded-pill">
                {t(item.checkStatus)}
              </CBadge>
            </CCardFooter>
          </CCard>
        </CCol>
      ))}
    </CRow>
  );
}
