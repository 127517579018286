import React from "react";
import {format} from "date-fns";
import {de, enGB, es, fr, pt} from "date-fns/locale";

const localeMap = {
  fr,
  es,
  pt,
  de,
  enGB
};

export function formatDateInLocale(date,locale,short) {
  if(typeof date !== 'undefined'){
    if(short){
      const dt = new Date(date);
      //locale = locale+'-'+locale.toUpperCase();
      return dt.toLocaleDateString(locale)+" " + dt.toLocaleTimeString(locale, {  hour: '2-digit', minute:'2-digit' } )
    }
    else{
      return format(new Date(date), 'eee dd MMMM, yyyy HH:mm', { locale: localeMap[locale]});
    }
  }
}

export default function capitalizeString(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
