import {
  CBadge,
  CButton,
  CCol,
  CRow,
} from "@coreui/react-pro";
import React, { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../ToastContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import getData from "src/components/data/getData";
import BeatLoader from "react-spinners/BeatLoader";
import {useAuth} from "../../hook/useAuth";
import CheckFormsList from "../../components/check/CheckFormsList";
export default function CheckForms() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addToastMessage } = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all");
  const { account } = useAuth();
  const accountEmail = account.email;
  const [checkForms, setCheckForms] = useState({
    pending: [],
    ongoing: [],
    finished: [],
  });

  const statusColors = {
    pending: "info",
    ongoing: "warning",
    finished: "success",
  };


  async function getCheckForms(isInitialLoad = false) {

    if (isInitialLoad) {
      setLoading(true);
    }
    try {

      if (!account || !account.email) {
        throw new Error("User email not found");
      }

      const pending = await getData(`/api/check_forms_by_status_and_account/pending/${accountEmail}`, "GET");
      const ongoing = await getData(`/api/check_forms_by_status_and_account/ongoing/${accountEmail}`, "GET");
      const finished = await getData(`/api/check_forms_by_status_and_account/finished/${accountEmail}`, "GET");

      setCheckForms({
        pending: Array.isArray(pending) ? pending : [],
        ongoing: Array.isArray(ongoing) ? ongoing : [],
        finished: Array.isArray(finished) ? finished : [],
      });
    } catch (error) {
      console.error(error);
      addToastMessage('danger', `${t("get_check_form_fail")}`);
    } finally {
      if (isInitialLoad) {
        setLoading(false);
      }
    }
  }


  useEffect(() => {
    getCheckForms(true);
  }, []);

  const handleCardClick = (formId) => {
    navigate(`/checkForms/checkForm/${formId}`);
  };

  const filteredForms = (filter === "all")
    ? [...checkForms.pending, ...checkForms.ongoing, ...checkForms.finished]
    : checkForms[filter];

  if (loading) {
    return <BeatLoader color="#6c6c6c" />;
  }

  return (
    <>
      <CRow className="mb-3">
        <CCol>
          <CButton className="m-2 text-white" color="primary" onClick={() => setFilter("all")}>
            {t("all")}<CBadge color="danger">{filteredForms.length}</CBadge>
          </CButton>
          <CButton className="m-2" color="info" onClick={() => setFilter("pending")}>
            {t("pending")} <CBadge color="danger">{checkForms.pending.length}</CBadge>
          </CButton>
          <CButton className="m-2" color="warning" onClick={() => setFilter("ongoing")}>
            {t("ongoing")} <CBadge color="danger">{checkForms.ongoing.length}</CBadge>
          </CButton>
          <CButton className="m-2" color="success" onClick={() => setFilter("finished")}>
            {t("finished")} <CBadge color="danger">{checkForms.finished.length}</CBadge>
          </CButton>

        </CCol>
      </CRow>

      <CheckFormsList checkForms={filteredForms} handleCardClick={handleCardClick} statusColors={statusColors} />
    </>
  );
}
