import { useCallback, useEffect, useState } from "react";
import { useAccountStore } from "src/store/store";
import { useNavigate } from "react-router-dom";
import Cookie from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import i18n from "src/i18n.js";
import { useTranslation } from "react-i18next";

export const AuthStatus = {
  Unknown: 0,
  Authenticated: 1,
  Guest: 2,
};

export function useAuth() {
  const { account, setAccount } = useAccountStore();
  const navigate = useNavigate();
  const [messageFlash, setMessageFlash] = useState(null); //type error or success
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();
  let [expire, setExpire] = useState(0);
  let [counter, setCounter] = useState(0);

  const api_url = process.env.REACT_APP_API_URL;
  const api_url_license = process.env.REACT_APP_API_URL_LICENSES;

  let status;
  switch (account) {
    case null:
      status = AuthStatus.Guest;
      break;
    case undefined:
      status = AuthStatus.Unknown;
      break;
    default:
      status = AuthStatus.Authenticated;
      break;
  }

  const authenticate = useCallback(() => {
    const token = Cookie.get('token');

    if (!token) {
      setAccount(null);
      navigate('/login');
      return;
    }
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now().valueOf() / 1000;
      if (decodedToken.exp < currentTime) {
        setAccount(null);

        navigate('/login');
        return;
      }

      let level = 100;
      let serviceSelected = [];
      if (decodedToken.services.length === 0) {
        serviceSelected = {
          id: null,
          name: null,
          level: null
        };
      }

      if (decodedToken.productLicense.includes('733100')) {
        decodedToken.services.forEach((service) => {
          if (service.level < level) {
            level = service.level;
            serviceSelected = service;
          }
        });
      }

      const userLicenses = {
        codes: decodedToken.productLicense
      }

      if (!localStorage.getItem("serviceSelected"))
        localStorage.setItem("serviceSelected", JSON.stringify(serviceSelected));

      localStorage.setItem("userLicense", JSON.stringify(userLicenses));
      localStorage.setItem("projectManager", JSON.stringify(decodedToken.projectManager));

      const userConnected = {
        id: decodedToken.id,
        email: decodedToken.email,
        roles: decodedToken.roles,
        services: decodedToken.services,
        firstname: decodedToken.firstname,
        lastname: decodedToken.lastname,
        mailAccounts: decodedToken.mailAccounts,
        accountLicenceIds: decodedToken.accountLicenceIds,
        productLicense: decodedToken.productLicense,
        projectManager: decodedToken.projectManager,
      }

      if (typeof Cookie.get('Auth') === 'undefined') {
        const userLanguage = decodedToken.language;
        i18n.changeLanguage(userLanguage);
      }

      setAccount(userConnected);
      Cookie.set('Auth', 'true');

    } catch (error) {
      console.error(error);
      setAccount(null);
      navigate('/login');
    }
  }, [setAccount, navigate]);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  const login = useCallback((username, password) => {
    fetch(`${api_url}/api/login_check`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    }).then((response) => {
      if (response.ok) return response.json();
      else {
        return response.json().then(error => {
          const formattedErrorMessage = error.message.toLowerCase().replace(" ", "_").replace(".", "");
          throw new Error(t(formattedErrorMessage));
        });
      }
    }).then((data) => {
      Cookie.set('token', data.token);
      Cookie.set('refresh_token', data.refresh_token);
      Cookie.set('refresh_token_expiration', data.refresh_token_expiration);
      const userToken = jwtDecode(data.token);
      if (userToken.productLicense.length === 0) {
        setMessageFlash({ type: 'error', message: t('user_no_licenses') });
        return;
      }

      let serviceSelected;
      if (userToken.services.length === 0) {
        serviceSelected = {
          id: null,
          name: null,
          level: null
        };
      } else {
        serviceSelected = {
          id: userToken.services[0].id,
          name: userToken.services[0].name,
        };
      }

      const userLicenses = {
        codes: userToken.productLicense
      };

      localStorage.setItem("userLicense", JSON.stringify(userLicenses));
      localStorage.setItem("projectManager", JSON.stringify(userToken.projectManager));

      const userConnected = {
        id: userToken.id,
        email: userToken.email,
        roles: userToken.roles,
        services: userToken.services,
        firstname: userToken.firstname,
        lastname: userToken.lastname,
        productLicense: userToken.productLicense,
        mailAccounts: userToken.mailAccounts,
        accountLicenceIds: userToken.accountLicenceIds,
        projectManager: userToken.projectManager,
      };

      getTokenLicense(username, password);
      setAccount(userConnected);
      global.getAccountTheme(false);
    }).catch((error) => {
      setMessageFlash({ type: "error", message: error.message });
    });
  }, [setAccount, t]);

  function getTokenLicense(username, password) {
    fetch(`${api_url_license}/api/login_check`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    }).then((response) => {
      if (response.ok) return response.json();
      else {
        return response.json().then(error => {
          const formattedErrorMessage = error.message.toLowerCase().replace(" ", "_").replace(".", "");
          throw new Error(t(formattedErrorMessage));
        });
      }
    }).then((data) => {
      Cookie.set('tokenLicense', data.token);
      navigate('/');
    }).catch((error) => {
      setMessageFlash({ type: "error", message: error.message });
    });
  }

  useEffect(() => {
    if (account) {
      let now = Math.floor(Date.now() / 1000);
      let expireTo = parseInt(Cookie.get('refresh_token_expiration'));
      let delay = (1000 * (expireTo - now)) - 60000;
      expire = delay;
      setExpire(delay);
      setTimeout(refreshToken, expire);
    }
  }, [account, refresh]);

  function refreshToken() {
    setCounter(counter + 1);
    fetch(`${api_url}/api/token/refresh`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${Cookie.get('token')}`,
      },
      body: JSON.stringify({ 'refresh_token': `${Cookie.get('refresh_token')}` })
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    }).then((data) => {
      Cookie.set('token', data.token);
      Cookie.set('refresh_token', data.refresh_token);
      Cookie.set('refresh_token_expiration', data.refresh_token_expiration);
      let now = Math.floor(Date.now() / 1000);
      setExpire(1000 * (data.refresh_token_expiration - now));
      setRefresh(!refresh);
    }).catch((error) => {
      console.error("Error fetching data:", error);
      if (error.message === "Network response was not ok") {
        Cookie.remove('token');
        Cookie.remove('refresh_token');
        navigate('/login');
      }
    });
  }

  return {
    account,
    setAccount,
    status,
    authenticate,
    login,
    messageFlash,
  };
}
